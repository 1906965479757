<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">注销账号</div>
    </div>
    <div v-if="status === 1">
      <div class="h3">注销当前账号</div>
      <div class="divMobile">将{{ mobile }}所绑定的账号注销</div>
      <div class="promptBox">
        <div class="topDiv">
          <img src="../../../image/my/t.png" alt="" />重要
        </div>
        <div class="botDiv">
          注销账号为不可恢复操作，注销账号后您将无法找回本账号，及本账号相关的内容与信息。
        </div>
      </div>
      <div class="statement">
        注销声明：请您认真已阅读，理解并同意以下内容：
      </div>
      <div class="statementBox">
        1、风急天高猿啸哀，渚清沙白鸟飞回。无边落木萧萧下，不尽长江滚滚来。万里悲秋常作客，百年多病独登台。艰难苦恨繁霜鬓，潦倒新停浊酒杯。
      </div>
      <div class="statementBox">
        1、风急天高猿啸哀，渚清沙白鸟飞回。无边落木萧萧下，不尽长江滚滚来。万里悲秋常作客，百年多病独登台。艰难苦恨繁霜鬓，潦倒新停浊酒杯。
      </div>
    </div>
    <div v-if="status === 2">
      <div class="h3">验证手机号</div>
      <div class="divMobile">请通过手机号{{ mobile }}发送验证码</div>
      <div class="codeBox">
        <input type="text" v-model="code" placeholder="请输入验证码" />
        <div class="codeDiv" v-if="isShow" @click="obtainCode()">
          获取验证码
        </div>
        <div class="codeDiv1" v-if="!isShow">
          <span>{{ this.count }}s</span>重新获取
        </div>
      </div>
    </div>
    <div class="outBtn" v-if="status === 1" @click="switchEvent()">
      我已知晓并同意，仍要注销
    </div>
    <div class="outBtn" v-if="status === 2" @click="switchEvent1()">
      立即注销
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { logOffPage, userLogoff } from "../../../api/circle";
import { send } from "../../../api/user";
import { Dialog } from "vant";
export default {
  data() {
    return {
      mobile: this.$route.query.mobile,
      status: 1,
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      count: 0, //倒计时 计数器
      code: "", //验证码
    };
  },
  mounted() {
    console.log(this.$store.state.user.mobile);
  },
  methods: {
    goBack() {
      if (this.status === 1) {
        this.$router.push({ path: "/accountManage" });
      } else {
        this.status = 1;
      }
    },
    switchEvent() {
      this.status = 2;
    },
    // 获取验证码
    obtainCode() {
      const phoneReg = /^1[3456789]\d{9}$/;
      if (!phoneReg.test(this.$store.state.user.mobile)) {
        Toast("请输入正确的手机号");
      } else {
        this.isShow = false;
        this.count = 5;
        var times = setInterval(() => {
          this.count--; //递减
          if (this.count <= 0) {
            // <=0 变成获取按钮
            this.isShow = true;
            clearInterval(times);
          }
        }, 1000); //1000毫秒后执行
        send({ mobile: this.$store.state.user.mobile, type: 5 }).then((res) => {
          console.log(res, "注销账号获取验证码");
        });
      }
    },
    // 立即注销
    switchEvent1() {
      Dialog.confirm({
        title: "",
        message: "确定要注销当前账号吗？",
      })
        .then(() => {
          // on confirm
          userLogoff({ code: this.code }).then((res) => {
            console.log(res, "注销");
            if (res.data.code === 0) {
              Toast("注销成功");
              this.$router.push({ path: "/login" });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 232px;
}
.h3 {
  font-size: 36px;
  font-weight: 600;
  color: #0b1526;
  margin-top: 54px;
  margin-left: 32px;
}
.divMobile {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 32px;
  margin-left: 32px;
}
.promptBox {
  width: 686px;
  height: 212px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 32px;
  margin-left: 32px;
  overflow: hidden;
}
.topDiv {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 600;
  color: #fa2819;
  margin-top: 38px;
}
.topDiv img {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  margin-left: 32px;
}
.botDiv {
  width: 622px;
  height: 88px;
  font-size: 28px;
  font-weight: 400;
  line-height: 44px;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 16px;
}
.statement {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 32px;
}
.statementBox {
  width: 686px;
  height: 132px;
  font-size: 28px;
  line-height: 44px;
  font-weight: 400;
  color: #455669;
  margin-left: 32px;
  margin-top: 32px;
}
.codeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 686px;
  height: 88px;
  background: #ffffff;
  border-bottom: 1px solid #f6f8fa;
  margin-left: 32px;
  margin-top: 78px;
}
.codeBox input {
  border: none;
  width: 200px;
  font-size: 28px;
  color: #97a4b4;
}
.codeDiv {
  margin-right: 32px;
  font-size: 28px;
  font-weight: 400;
  color: #0957c3;
}
.codeDiv1 {
  font-size: 28px;
  font-weight: 400;
  color: #455669;
  margin-right: 32px;
}
.codeDiv1 span {
  color: #0957c3;
}
.outBtn {
  width: 684px;
  height: 88px;
  background: rgba(250, 40, 25, 0.1);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  font-weight: 600;
  color: #fa2819;
  text-align: center;
  line-height: 88px;
  position: fixed;
  bottom: 32px;
  left: 32px;
}
</style>
